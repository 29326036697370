import { useEffect, useState } from "react";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const validateTime = (time: number): string => {
  const validTime = time < 0 ? 0 : time;

  if (validTime.toString()?.length === 1) {
    return `0${validTime}`;
  }

  return validTime.toString();
};

export const useCountdownTimer = (
  targetTime: string,
): { time: string | null; isTimeOver: boolean } => {
  const [remainingTime, setRemainingTime] = useState<string | null>(null);
  const [isTimeOver, setIsTimeOver] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = dayjs();
      const difference = dayjs(targetTime).utc(true).diff(currentTime);

      const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
      const seconds = Math.floor((difference / 1000) % 60);
      let minutes = Math.floor((difference / (1000 * 60)) % 60);

      minutes = hours * 60 + minutes;

      setRemainingTime(`${validateTime(minutes)}:${validateTime(seconds)}`);
      if (minutes < 5 && !isTimeOver) {
        setIsTimeOver(true);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [targetTime]);

  return { time: remainingTime, isTimeOver };
};
