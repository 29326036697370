import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export const calculateTime = (date: string) => {
  const time = dayjs(date).add(15, "minutes").toISOString();

  return time;
};
