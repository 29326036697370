import { RetrieveActiveConsultation } from "types/consultation";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

export const currentConsultationMapper = (
  data: RetrieveActiveConsultation,
): RetrieveActiveConsultation => {
  return {
    ...data,
    start_time: data?.start_time
      ? dayjs(data.start_time).utc(true).toISOString()
      : data?.start_time,
    started_at: data?.started_at
      ? dayjs(data.started_at).utc(true).toISOString()
      : data?.started_at,
    created_at: data?.created_at
      ? dayjs(data.created_at).utc(true).toISOString()
      : data?.created_at,
    consultation_request: {
      ...data.consultation_request,
      created_at: data?.consultation_request?.created_at
        ? dayjs(data.consultation_request.created_at).utc(true).toISOString()
        : data?.consultation_request?.created_at,
    },
  };
};
