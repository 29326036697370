export enum ConsultationStatus {
  Pending = "pending",
  Accepted = "accepted",
  Started = "started",
  Rejected = "rejected",
  Canceled = "canceled",
  Completed = "completed",
  Failed = "failed",
  Expired = "expired",
}

export enum ConsultationType {
  Audio = "audio",
  Video = "video",
}

export type NameInfo = {
  nickname: string;
  first_name: string;
  last_name: string;
};

export type RetrieveConsultationRequest = {
  id: number;
  client: NameInfo;
  counselor: NameInfo;
  state: {
    name: string;
  };
  answers: [
    {
      text: string[];
      question: {
        id: number;
        group_order: number;
        text: string;
      };
    },
    {
      text: number;
      question: {
        id: number;
        group_order: number;
        text: string;
      };
    },
    {
      text: string[];
      question: {
        id: number;
        group_order: number;
        text: string;
      };
    },
    {
      text: string;
      question: {
        id: number;
        group_order: number;
        text: string;
      };
    },
    {
      text: string;
      question: {
        id: number;
        group_order: number;
        text: string;
      };
    },
    {
      text: string;
      question: {
        id: number;
        group_order: number;
        text: string;
      };
    },
    {
      text: string;
      question: {
        id: number;
        group_order: number;
        text: string;
      };
    },
    {
      text: {
        YES_OR_NO: string;
        "Would you please describe?": null;
      };
      question: {
        id: number;
        group_order: number;
        text: string;
      };
    },
    {
      text: string;
      question: {
        id: number;
        group_order: number;
        text: string;
      };
    },
    {
      text: {
        ethnicity: string;
        "Would you please describe?": null;
      };
      question: {
        id: number;
        group_order: number;
        text: string;
      };
    },
    {
      text: string;
      question: {
        id: number;
        group_order: number;
        text: string;
      };
    },
  ];
  sign_up_answers: [
    {
      text: string;
      question: {
        id: number;
        group_order: number;
        text: string;
      };
    },
    {
      text: string;
      question: {
        id: 1;
        group_order: number;
        text: string;
      };
    },
    {
      text: string[];
      question: {
        id: number;
        group_order: number;
        text: string;
      };
    },
    {
      text: string[];
      question: {
        id: number;
        group_order: number;
        text: string;
      };
    },
    {
      text: string[];
      question: {
        id: number;
        group_order: number;
        text: string;
      };
    },
    {
      text: string;
      question: {
        id: number;
        group_order: number;
        text: string;
      };
    },
    {
      text: {
        "Legal Name": string;
        "Phone number": string;
        Relationship: string;
      };
      question: {
        id: number;
        group_order: number;
        text: string;
      };
    },
    {
      text: {
        YES_OR_NO: string;
        "When was the last time you had?": string;
        "Did you take any medication?": string;
        "Were you hospitalized before?": string;
        "What was the duration of the treatment?": string;
        "Have you fully recovered since then?": string;
        "Are you currently taking any medication or receiving a long-term treatment plan?": string;
      };
      question: {
        id: number;
        group_order: number;
        text: string;
      };
    },
    {
      text: {
        Name: string;
        "Contact information (phone number)": string;
        "Office address": string;
      };
      question: {
        id: number;
        group_order: number;
        text: string;
      };
    },
    {
      text: string;
      question: {
        id: number;
        group_order: number;
        text: string;
      };
    },
  ];
  duration_price: {
    duration: number;
    price: string;
  };
  type: ConsultationType;
  payment_method_id: string;
  status: ConsultationStatus;
  created_at: string;
};

export type RetrieveActiveConsultation = {
  consultation_request: RetrieveConsultationRequest;
  room_name: string;
  room_sid: string;
  client_access_token: string;
  counselor_access_token: string;
  created_at: string;
  started_at: string;
  start_time: string;
};

export type TakeAwaysPayload = {
  id: string;
  key_takeaway: string;
};

export type UserProfileData = {
  name: string | null;
  ethnicity: string | null;
  gender: string | null;
  language: string[] | null;
  industries: string[] | null;
};

export type MentalHealthBackground = {
  diagnosedBefore: string | null;
  lastTime: string | null;
  medications: string | null;
  hospitalizedBefore: string | null;
  treatmentDuration: string | null;
  fullyRecoveredSince: string | null;
  currentlyTakingAnyMedication: string | null;
  haveSeenCounselorBefore: string | null;
};

export type EmergencyContactData = {
  legalName: string | null;
  phoneNumber: string | null;
  relationship: string | null;
};

export type DoctorInformationData = {
  name: string | null;
  phoneNumber: string | null;
  address: string | null;
};

export type ScreeningQuestionsData = {
  feelingsToday: string[] | null;
  feelingsScale: number | null;
  feelingsComeFrom: string[] | null;
  sleepingPatterns: string | null;
  panicAttack: string | null;
  counselorLastVisit: string | null;
  medication: string | null;
  suicideExpirience: string | null;
  counselorGender: string | null;
  counselorLanguage: string | null;
  counselorEthnicity: string | null;
};

export type ProfileQuestioneer = {
  time: number;
  type: ConsultationType;
  profile: UserProfileData;
  emergencyContact: EmergencyContactData | null;
  mentalHealthBackground: MentalHealthBackground;
  doctorInformation: DoctorInformationData | null;
  additionalInformation: string[] | null;
  screeningQuestions: ScreeningQuestionsData | null;
};

export const mockeddata: RetrieveActiveConsultation = {
  consultation_request: {
    id: 345,
    client: {
      nickname: "patient",
      first_name: "Viktoriia",
      last_name: "Lekh",
    },
    counselor: {
      nickname: "Counselor X",
      first_name: "Vika",
      last_name: "Lekh",
    },
    state: null,
    answers: [
      {
        text: ["Depressed", "Embarrassed", "Angery"],
        question: {
          id: 9,
          group_order: 1,
          text: "How can we assist you today?",
        },
      },
      {
        text: 4,
        question: {
          id: 10,
          group_order: 2,
          text: "On a scale of 1 to 5, how intense is your feeling?",
        },
      },
      {
        text: [
          "Health & Medical Conditions",
          "Identity & Race",
          "Life Transition",
        ],
        question: {
          id: 11,
          group_order: 3,
          text: "Where does that feeling come from?",
        },
      },
      {
        text: "Yes",
        question: {
          id: 16,
          group_order: 8,
          text: "Have you been experiencing any thoughts of self-harm or suicide, or harming others?",
        },
      },
      {
        text: "Good",
        question: {
          id: 12,
          group_order: 4,
          text: "How would you characterize your current sleeping patterns or habits?",
        },
      },
      {
        text: "Yes",
        question: {
          id: 13,
          group_order: 5,
          text: "Are you currently experiencing a panic attack?",
        },
      },
      {
        text: "I am currently seeing one on a regular basis.",
        question: {
          id: 14,
          group_order: 6,
          text: "When did you last visit a mental health counselor or psychiatrist?",
        },
      },
      {
        text: {
          YES_OR_NO: "Yes",
          "Would you please describe?": null,
        },
        question: {
          id: 15,
          group_order: 7,
          text: "Are you currently taking any medication or undergoing any form of treatment?",
        },
      },
      {
        text: "Female",
        question: {
          id: 17,
          group_order: 9,
          text: "Do you have a preference for the gender of your counselor?",
        },
      },
      {
        text: {
          ethnicity: "Asian",
          "Would you please describe?": null,
        },
        question: {
          id: 19,
          group_order: 11,
          text: "Do you have any ethnicity preference for your counselor?",
        },
      },
      {
        text: "English",
        question: {
          id: 18,
          group_order: 10,
          text: "Do you have a preferred language for your counselor?",
        },
      },
    ],
    sign_up_answers: [
      {
        text: "LGBTQ+",
        question: {
          id: 20,
          group_order: 0,
          text: "What’s your gender?",
        },
      },
      {
        text: "American Indian and Alaska Native",
        question: {
          id: 1,
          group_order: 1,
          text: "What’s your ethnicity?",
        },
      },
      {
        text: ["English, Ukraine, English, other"],
        question: {
          id: 2,
          group_order: 2,
          text: "What’s your preferred language(s)?",
        },
      },
      {
        text: [
          "Education",
          "Entertainment",
          "Freelance",
          "Finance",
          "Legal",
          "Manufacture",
          "Student",
          "Retired",
          "Technology",
          "Retail",
          "Healthcare",
          "Entrepreneur",
          "Artist",
        ],
        question: {
          id: 3,
          group_order: 3,
          text: "Please choose the industry you are involved in.",
        },
      },
      {
        text: ["unemployed", "student", "disabled", "dating", "parent"],
        question: {
          id: 8,
          group_order: 9,
          text: "Select all that apply",
        },
      },
      {
        text: "No",
        question: {
          id: 6,
          group_order: 7,
          text: "Did you experience any thoughts of self-harm or suicide, or harming others in the past?",
        },
      },
      {
        text: {
          "Legal Name": "Jhon",
          "Phone number": "+380957178151",
          Relationship: "test",
        },
        question: {
          id: 4,
          group_order: 4,
          text: "Please provide your emergency contact.",
        },
      },
      {
        text: {
          YES_OR_NO: "Yes",
          "When was the last time you had?": "time you had",
          "Did you take any medication?": "any medication",
          "Were you hospitalized before?": "hospitalized before",
          "What was the duration of the treatment?": "treatment",
          "Have you fully recovered since then?": "Yes",
          "Are you currently taking any medication or receiving a long-term treatment plan?":
            "No",
        },
        question: {
          id: 5,
          group_order: 5,
          text: "Have you been diagnosed with a mental illness or receiving long-term mental health treatment before?",
        },
      },
      {
        text: {
          Name: "Jhon",
          "Contact information (phone number)": "+380957178152",
          "Office address": "rrrrr",
        },
        question: {
          id: 7,
          group_order: 8,
          text: "Please provide your primary care doctor information.",
        },
      },
      {
        text: "No",
        question: {
          id: 21,
          group_order: 6,
          text: "Have you seen a counselor before?",
        },
      },
    ],
    duration_price: {
      duration: 60,
      price: "150.00",
    },
    type: ConsultationType.Video,
    payment_method_id: "pm_1OlqWbKsnbCOrgCR1YuDizQv",
    status: ConsultationStatus.Started,
    created_at: "2024-03-12T14:44:42.009447Z",
  },
  room_name: "patient at 2024-03-12 02:45 PM",
  room_sid: "RM3b5e8e80523645719eb14ca033200e59",
  client_access_token:
    "eyJhbGciOiJIUzI1NiIsImN0eSI6InR3aWxpby1mcGE7dj0xIiwidHlwIjoiSldUIn0.eyJqdGkiOiJTSzdmN2QxMWFjOTlkYzE0MDZmZjEwYTc0MWQ1YzhiNTM0LTE3MTAyNTQ3MjEiLCJncmFudHMiOnsidmlkZW8iOnsicm9vbSI6InBhdGllbnQgYXQgMjAyNC0wMy0xMiAwMjo0NSBQTSJ9LCJpZGVudGl0eSI6InBhdGllbnQifSwiaXNzIjoiU0s3ZjdkMTFhYzk5ZGMxNDA2ZmYxMGE3NDFkNWM4YjUzNCIsImV4cCI6MTcxMDI1ODMyMSwibmJmIjoxNzEwMjU0NzIxLCJzdWIiOiJBQ2I2ZGJiZDQ0ZmI3Mjg1YTI2MDRiMTgyZGFjYmM4YzcxIn0.gqIH89u17_nJIFJtCSNWA1caFGn7-78irkoXbreBMtg",
  counselor_access_token:
    "eyJhbGciOiJIUzI1NiIsImN0eSI6InR3aWxpby1mcGE7dj0xIiwidHlwIjoiSldUIn0.eyJqdGkiOiJTSzdmN2QxMWFjOTlkYzE0MDZmZjEwYTc0MWQ1YzhiNTM0LTE3MTAyNTQ3MjEiLCJncmFudHMiOnsidmlkZW8iOnsicm9vbSI6InBhdGllbnQgYXQgMjAyNC0wMy0xMiAwMjo0NSBQTSJ9LCJpZGVudGl0eSI6IkNvdW5zZWxvciBYIn0sImlzcyI6IlNLN2Y3ZDExYWM5OWRjMTQwNmZmMTBhNzQxZDVjOGI1MzQiLCJleHAiOjE3MTAyNTgzMjEsIm5iZiI6MTcxMDI1NDcyMSwic3ViIjoiQUNiNmRiYmQ0NGZiNzI4NWEyNjA0YjE4MmRhY2JjOGM3MSJ9.PevsU6olCRLImyayeZvjkNbFi7TqKPoAqONyv_1lz20",
  created_at: "2024-03-12T14:45:21.633467Z",
  started_at: "2024-03-12T14:46:54.002122Z",
  start_time: "2024-03-12T14:46:54.002122Z",
};
